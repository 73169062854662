import '../App.scss';
import './navbar.scss'
import '../global.css'
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Switcher as SwitcherIcon, Notification, UserAvatar, Logout, Settings } from '@carbon/icons-react';
import { FaUser, FaInfoCircle } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';
import {
  Header,
  HeaderContainer,
  HeaderName,
  HeaderNavigation,
  HeaderMenuButton,
  HeaderMenuItem,
  HeaderGlobalBar,
  HeaderGlobalAction,
  SkipToContent,
  SideNav,
  SideNavItems,
  HeaderSideNavItems,
  HeaderPanel,
  Switcher,
  SwitcherDivider,
  SwitcherItem,
} from '@carbon/react';
import { Link } from 'react-router-dom';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";

export default function NavBar({
  productionBuildVersion,
  active_account,
  cookie_has_been_assigned,
  global_account_access_tier,
  account_info_from_cookie,
  side_panel_visible,
  setSide_panel_visible,
  appSwitcherVisible,
  setAppSwitcherVisible,
  selectedGlobalAction,
  setSelectedGlobalAction,
  intercom_routing_type,
  setIntercom_routing_type,
  intercomStatusSubLink,
  setIntercomStatusSubLink,
  settings_routing_type,
  setSettings_routing_type,
  login_popup_func,
  logout_func,
  advancedRoutesSubLink,
  setAdvancedRoutesSubLink,
  captioningSubLink,
  setCaptioningSubLink,
  scheduleSubLink,
  setScheduleSubLink,
  serverPermissions,
  polatisSubLink,
  setPolatisSubLink,
  ccuOrRcpSubLink,
  setCcuOrRcpSubLink,
  loggedInUserPageAccess,
  setSettingsModalInfo,
}) {

  useEffect(() => {
    console.log("COOKIE INFO PASSED TO NAV", account_info_from_cookie)
  }, [account_info_from_cookie])

  useEffect(() => {
    console.log("Logged in user access", loggedInUserPageAccess)
  }, [loggedInUserPageAccess])

  // console.log("PREST OR KEYS ROUTING", intercom_routing_type)

  const navigate = useNavigate()
  const pathname = useLocation().pathname
  const prodBuildIP = "10.239.233.166"
  const prodBuildHost = "https://lbx-lira.liberty.edu/"
  const developmentBuildIP = "10.239.233.143"
  const developmentBuildHost = "https://lbx-lira-dev.liberty.edu/"
  const url = window.location.href
  const [filtered_breadcrumbs, setFiltered_breadcrumbs] = useState([])

  useEffect(() => {
    const breadcrumbs = pathname.split("/")
    setFiltered_breadcrumbs(breadcrumbs.filter(path => path !== ""))
    console.log("pathname", pathname)
  }, [pathname])

  return (
    <HeaderContainer
      render={({ isSideNavExpanded, onClickSideNavExpand }) => (
        <Header aria-label="LIRA Header">
          {/* <HeaderMenuButton
            id="side_panel_hamburger"
            style={{
              display: "flex"
            }}
            aria-label="Menu"
            onClick={() => setSide_panel_visible(!side_panel_visible)}
            isActive={side_panel_visible ? onClickSideNavExpand : isSideNavExpanded}
          /> */}
          <div className='flex items-center justify-center pl-2'><Link to="/"><HeaderName prefix="" style={{ padding: 0, cursor: "default" }}
            onClick={() => setSide_panel_visible(false)}
          >LBX Schedule</HeaderName></Link>
          {filtered_breadcrumbs?.length > 0 && filtered_breadcrumbs?.map((crumb, index) => {
                if (index > 0) return null
                //* Add abreviations here as an array
                if (["nmos"].includes(crumb?.toLowerCase())) {
                  return (
                    <>
                      <span className='px-2'>/</span>
                      <Link to={`/${crumb}`}><HeaderName prefix="" style={{ padding: 0 }}
                        onClick={() => setSide_panel_visible(false)}
                      >NMOS</HeaderName></Link>
                    </>
                  )
                } else if (["advanced-routes"].includes(crumb?.toLowerCase())) {
                  return (
                    <>
                      <span className='px-2'>/</span>
                      <Link to={`/${crumb}`}><HeaderName prefix="" style={{ padding: 0 }}
                        onClick={() => setSide_panel_visible(false)}
                      >Advanced Routes</HeaderName></Link>
                    </>
                  )
                } else if (["ptp"].includes(crumb?.toLowerCase())) {
                  return (
                    <>
                      <span className='px-2'>/</span>
                      <Link to={`/${crumb}`}><HeaderName prefix="" style={{ padding: 0 }}
                        onClick={() => setSide_panel_visible(false)}
                      >PTP</HeaderName></Link>
                    </>
                  )
                } else if (["ccu"].includes(crumb?.toLowerCase())) {
                  return (
                    <>
                      <span className='px-2'>/</span>
                      <Link to={`/${crumb}`}><HeaderName prefix="" style={{ padding: 0 }}
                        onClick={() => setSide_panel_visible(false)}
                      >CCU</HeaderName></Link>
                    </>
                  )
                } else if (["omni"].includes(crumb?.toLowerCase())) {
                  return (
                    <>
                      <span className='px-2'>/</span>
                      <Link to={`/${crumb}`}><HeaderName prefix="" style={{ padding: 0 }}
                        onClick={() => setSide_panel_visible(false)}
                      >Omni Router</HeaderName></Link>
                    </>
                  )
                } else {
                  return (
                    <>
                      <span className='px-2'>/</span>
                      <Link to={`/${crumb}`}><HeaderName prefix="" style={{ padding: 0 }}
                        onClick={() => setSide_panel_visible(false)}
                      >{crumb[0]?.toUpperCase()}{crumb.slice(1)}</HeaderName></Link>
                    </>
                  )
                }
              })}


          <HeaderNavigation aria-label="LIRA Header Navigation">
              {pathname.includes("intercom/bolero") &&
              //* Intercom Bolero Presets Page - "presets" or "keys"
              <span className='text-[grey]'><span className='px-2'>|</span>
                <span className='px-2 hover:text-white' style={{ cursor: "pointer", color: intercom_routing_type === "presets" ? "white" : "", textDecoration: intercom_routing_type === "presets" ? "underline" : "" }}
                  onClick={() => {
                    setIntercom_routing_type("presets")
                  }}
                >Presets</span>
                <span className='px-2 hover:text-white' style={{ cursor: "pointer", color: intercom_routing_type === "keys" ? "white" : "", textDecoration: intercom_routing_type === "keys" ? "underline" : "" }}
                  onClick={() => {
                    setIntercom_routing_type("keys")
                  }}
                >Keys</span>
                <span className='px-2 hover:text-white' style={{ cursor: "pointer", color: intercom_routing_type === "salvos" ? "white" : "", textDecoration: intercom_routing_type === "salvos" ? "underline" : "" }}
                  onClick={() => {
                    setIntercom_routing_type("salvos")
                  }}
                >Salvos</span>
              </span>}

            {pathname === ("/intercom") &&
              //* Intercom Bolero Presets Page - "presets" or "keys"
              <span className='text-[grey]'><span className='px-2'>|</span>
                <span className='px-2 hover:text-white' style={{ cursor: "pointer", color: intercomStatusSubLink === "logic" ? "white" : "", textDecoration: intercomStatusSubLink === "logic" ? "underline" : "" }}
                  onClick={() => {
                    setIntercomStatusSubLink("logic")
                  }}
                >Logic</span>
              </span>}

            {pathname.includes("advanced-routes") &&
              //* Advance Routes Page - "routes" or "salvos"
              <span className='text-[grey]'><span className='px-2'>|</span>
                <span className='px-2 hover:text-white' style={{ cursor: "pointer", color: advancedRoutesSubLink === "routes" ? "white" : "", textDecoration: advancedRoutesSubLink === "routes" ? "underline" : "" }}
                  onClick={() => {
                    setAdvancedRoutesSubLink("routes")
                  }}
                >Routes</span>
                <span className='px-2 hover:text-white' style={{ cursor: "pointer", color: advancedRoutesSubLink === "salvos" ? "white" : "", textDecoration: advancedRoutesSubLink === "salvos" ? "underline" : "" }}
                  onClick={() => {
                    setAdvancedRoutesSubLink("salvos")
                  }}
                >Salvos</span>
              </span>}

            {pathname.includes("captioning") &&
              //* Captioning Page - "pcra", "pcrb", "pcrc", "pcrz", "nest", "models"
              <span className='text-[grey]'><span className='px-2'>|</span>
                <span className='px-2 hover:text-white' style={{ cursor: "pointer", color: captioningSubLink === "pcra" ? "white" : "", textDecoration: captioningSubLink === "pcra" ? "underline" : "" }}
                  onClick={() => {
                    setCaptioningSubLink("pcra")
                  }}
                >PCR A</span>
                <span className='px-2 hover:text-white' style={{ cursor: "pointer", color: captioningSubLink === "pcrb" ? "white" : "", textDecoration: captioningSubLink === "pcrb" ? "underline" : "" }}
                  onClick={() => {
                    setCaptioningSubLink("pcrb")
                  }}
                >PCR B</span>
                <span className='px-2 hover:text-white' style={{ cursor: "pointer", color: captioningSubLink === "pcrc" ? "white" : "", textDecoration: captioningSubLink === "pcrc" ? "underline" : "" }}
                  onClick={() => {
                    setCaptioningSubLink("pcrc")
                  }}
                >PCR C</span>
                <span className='px-2 hover:text-white' style={{ cursor: "pointer", color: captioningSubLink === "pcrz" ? "white" : "", textDecoration: captioningSubLink === "pcrz" ? "underline" : "" }}
                  onClick={() => {
                    setCaptioningSubLink("pcrz")
                  }}
                >PCR Z</span>
                <span className='px-2 hover:text-white' style={{ cursor: "pointer", color: captioningSubLink === "nest" ? "white" : "", textDecoration: captioningSubLink === "nest" ? "underline" : "" }}
                  onClick={() => {
                    setCaptioningSubLink("nest")
                  }}
                >PCR Nest</span>
                <span className='px-2 hover:text-white' style={{ cursor: "pointer", color: captioningSubLink === "models" ? "white" : "", textDecoration: captioningSubLink === "models" ? "underline" : "" }}
                  onClick={() => {
                    setCaptioningSubLink("models")
                  }}
                >Models</span>
              </span>}

            {pathname.includes("ccu") &&
              //* CCU Page - "ccu" or "rcp"
              <span className='text-[grey]'><span className='px-2'>|</span>
                <span className='px-2 hover:text-white' style={{ cursor: "pointer", color: ccuOrRcpSubLink === "ccu" ? "white" : "", textDecoration: ccuOrRcpSubLink === "ccu" ? "underline" : "" }}
                  onClick={() => {
                    setCcuOrRcpSubLink("ccu")
                  }}
                >CCU</span>
                <span className='px-2 hover:text-white' style={{ cursor: "pointer", color: ccuOrRcpSubLink === "rcp" ? "white" : "", textDecoration: ccuOrRcpSubLink === "rcp" ? "underline" : "" }}
                  onClick={() => {
                    setCcuOrRcpSubLink("rcp")
                  }}
                >RCP</span>
              </span>}

            {pathname.includes("polatis") &&
              //* Polatis Page - "routes" or "advanced"
              <span className='text-[grey]'><span className='px-2'>|</span>
                <span className='px-2 hover:text-white' style={{ cursor: "pointer", color: polatisSubLink === "routes" ? "white" : "", textDecoration: polatisSubLink === "routes" ? "underline" : "" }}
                  onClick={() => {
                    setPolatisSubLink("routes")
                  }}
                >Routes</span>
                <span className='px-2 hover:text-white' style={{ cursor: "pointer", color: polatisSubLink === "advanced" ? "white" : "", textDecoration: polatisSubLink === "advanced" ? "underline" : "" }}
                  onClick={() => {
                    setPolatisSubLink("advanced")
                  }}
                >Advanced</span>
              </span>}
          </HeaderNavigation>
          </div>

          {/* Left Panel Navigation Links Start Here  */}

          <SideNav
            aria-label="Side navigation"
            expanded={side_panel_visible}
            isPersistent={false}
            style={{
              padding: "1rem 0",
            }}
          >
            <SideNavItems>
              {pathname.includes("intercom/bolero") && <HeaderSideNavItems>
                <HeaderMenuItem style={{ cursor: "pointer", color: intercom_routing_type === "presets" ? "white" : "", textDecoration: intercom_routing_type === "presets" ? "underline" : "" }}
                  onClick={() => {
                    setIntercom_routing_type("presets")
                  }}
                >Presets</HeaderMenuItem>
                <HeaderMenuItem style={{ cursor: "pointer", color: intercom_routing_type === "keys" ? "white" : "", textDecoration: intercom_routing_type === "keys" ? "underline" : "" }}
                  onClick={() => {
                    setIntercom_routing_type("keys")
                  }}
                >Keys</HeaderMenuItem>
                <HeaderMenuItem style={{ cursor: "pointer", color: intercom_routing_type === "salvos" ? "white" : "", textDecoration: intercom_routing_type === "salvos" ? "underline" : "" }}
                  onClick={() => {
                    setIntercom_routing_type("salvos")
                  }}
                >Salvos</HeaderMenuItem>
                <SwitcherDivider />
              </HeaderSideNavItems>}
              {pathname === ("/intercom") && <HeaderSideNavItems>
                <HeaderMenuItem style={{ cursor: "pointer", color: intercomStatusSubLink === "logic" ? "white" : "", textDecoration: intercomStatusSubLink === "logic" ? "underline" : "" }}
                  onClick={() => {
                    setIntercomStatusSubLink("logic")
                  }}
                >Logic</HeaderMenuItem>
                <SwitcherDivider />
              </HeaderSideNavItems>}
              {pathname.includes("advanced-routes") && <HeaderSideNavItems>
                <HeaderMenuItem style={{ cursor: "pointer", color: advancedRoutesSubLink === "routes" ? "white" : "", textDecoration: advancedRoutesSubLink === "routes" ? "underline" : "" }}
                  onClick={() => {
                    setAdvancedRoutesSubLink("routes")
                  }}
                >Routes</HeaderMenuItem>
                <HeaderMenuItem style={{ cursor: "pointer", color: advancedRoutesSubLink === "salvos" ? "white" : "", textDecoration: advancedRoutesSubLink === "salvos" ? "underline" : "" }}
                  onClick={() => {
                    setAdvancedRoutesSubLink("salvos")
                  }}
                >Salvos</HeaderMenuItem>
                <SwitcherDivider />
              </HeaderSideNavItems>}
              {pathname.includes("captioning") && <HeaderSideNavItems>
                <HeaderMenuItem style={{ cursor: "pointer", color: captioningSubLink === "pcra" ? "white" : "", textDecoration: captioningSubLink === "pcra" ? "underline" : "" }}
                  onClick={() => {
                    setCaptioningSubLink("pcra")
                  }}
                >PCR A</HeaderMenuItem>
                <HeaderMenuItem style={{ cursor: "pointer", color: captioningSubLink === "pcrb" ? "white" : "", textDecoration: captioningSubLink === "pcrb" ? "underline" : "" }}
                  onClick={() => {
                    setCaptioningSubLink("pcrb")
                  }}
                >PCR B</HeaderMenuItem>
                <HeaderMenuItem style={{ cursor: "pointer", color: captioningSubLink === "pcrc" ? "white" : "", textDecoration: captioningSubLink === "pcrc" ? "underline" : "" }}
                  onClick={() => {
                    setCaptioningSubLink("pcrc")
                  }}
                >PCR C</HeaderMenuItem>
                <HeaderMenuItem style={{ cursor: "pointer", color: captioningSubLink === "pcrz" ? "white" : "", textDecoration: captioningSubLink === "pcrz" ? "underline" : "" }}
                  onClick={() => {
                    setCaptioningSubLink("pcrz")
                  }}
                >PCR Z</HeaderMenuItem>
                <HeaderMenuItem style={{ cursor: "pointer", color: captioningSubLink === "nest" ? "white" : "", textDecoration: captioningSubLink === "nest" ? "underline" : "" }}
                  onClick={() => {
                    setCaptioningSubLink("nest")
                  }}
                >PCR Nest</HeaderMenuItem>
                <HeaderMenuItem style={{ cursor: "pointer", color: captioningSubLink === "models" ? "white" : "", textDecoration: captioningSubLink === "models" ? "underline" : "" }}
                  onClick={() => {
                    setCaptioningSubLink("models")
                  }}
                >Models</HeaderMenuItem>
                <SwitcherDivider />
              </HeaderSideNavItems>}
              {pathname.includes("ccu") && <HeaderSideNavItems>
                <HeaderMenuItem style={{ cursor: "pointer", color: ccuOrRcpSubLink === "ccu" ? "white" : "", textDecoration: ccuOrRcpSubLink === "ccu" ? "underline" : "" }}
                  onClick={() => {
                    setCcuOrRcpSubLink("ccu")
                  }}
                >CCU</HeaderMenuItem>
                <HeaderMenuItem style={{ cursor: "pointer", color: ccuOrRcpSubLink === "rcp" ? "white" : "", textDecoration: ccuOrRcpSubLink === "rcp" ? "underline" : "" }}
                  onClick={() => {
                    setCcuOrRcpSubLink("rcp")
                  }}
                >RCP</HeaderMenuItem>
                <SwitcherDivider />
              </HeaderSideNavItems>}
              {pathname.includes("polatis") && <HeaderSideNavItems>
                <HeaderMenuItem style={{ cursor: "pointer", color: polatisSubLink === "routes" ? "white" : "", textDecoration: polatisSubLink === "routes" ? "underline" : "" }}
                  onClick={() => {
                    setPolatisSubLink("routes")
                  }}
                >Routes</HeaderMenuItem>
                <HeaderMenuItem style={{ cursor: "pointer", color: polatisSubLink === "advanced" ? "white" : "", textDecoration: polatisSubLink === "advanced" ? "underline" : "" }}
                  onClick={() => {
                    setPolatisSubLink("advanced")
                  }}
                >Advanced</HeaderMenuItem>
                <SwitcherDivider />
              </HeaderSideNavItems>}
                {["full", "view"]?.includes(loggedInUserPageAccess?.magnum?.toLowerCase()) && cookie_has_been_assigned && (url.includes(developmentBuildIP) || url.includes("localhost") || url.includes(developmentBuildHost) || url.includes(prodBuildIP) || url.includes(prodBuildHost)) && <Link to="/advanced-routes" id="nav-bar-link-advanced-routes" style={{ listStyle: "none", paddingTop: "10px" }}
                  onClick={() => setSide_panel_visible(false)}
                ><HeaderMenuItem>Advanced Routes</HeaderMenuItem></Link>}
                {["full", "view"]?.includes(loggedInUserPageAccess?.captioning?.toLowerCase()) && cookie_has_been_assigned && (url.includes(developmentBuildIP) || url.includes("localhost") || url.includes(developmentBuildHost) || url.includes(prodBuildIP) || url.includes(prodBuildHost)) && <Link to="/captioning" id="nav-bar-link-captioning" style={{ listStyle: "none", paddingTop: "10px" }}
                  onClick={() => setSide_panel_visible(false)}
                ><HeaderMenuItem>Captioning</HeaderMenuItem></Link>}
                {["full", "view"]?.includes(loggedInUserPageAccess?.ccu?.toLowerCase()) && cookie_has_been_assigned && (url.includes(developmentBuildIP) || url.includes("localhost") || url.includes(developmentBuildHost) || url.includes(prodBuildIP) || url.includes(prodBuildHost)) && <Link to="/ccu" id="nav-bar-link-cuu" style={{ listStyle: "none", paddingTop: "10px" }}
                  onClick={() => setSide_panel_visible(false)}
                ><HeaderMenuItem>CCU</HeaderMenuItem></Link>}
                {["full", "view"]?.includes(loggedInUserPageAccess?.dante?.toLowerCase()) && cookie_has_been_assigned && (url.includes(developmentBuildIP) || url.includes("localhost") || url.includes(developmentBuildHost) || url.includes(prodBuildIP) || url.includes(prodBuildHost)) && <Link to="/dante" id="nav-bar-link-dante" style={{ listStyle: "none", paddingTop: "10px" }}
                  onClick={() => setSide_panel_visible(false)}
                ><HeaderMenuItem>Dante</HeaderMenuItem></Link>}
                {["full", "view"]?.includes(loggedInUserPageAccess?.engineering?.toLowerCase()) && cookie_has_been_assigned && (url.includes(developmentBuildIP) || url.includes("localhost") || url.includes(developmentBuildHost) || url.includes(prodBuildIP) || url.includes(prodBuildHost)) && <Link to="/engineering" id="nav-bar-link-engineering" style={{ listStyle: "none", paddingTop: "10px" }}
                  onClick={() => setSide_panel_visible(false)}
                ><HeaderMenuItem>Engineering</HeaderMenuItem></Link>}
                {["full", "view"]?.includes(loggedInUserPageAccess?.intercom?.toLowerCase()) && cookie_has_been_assigned && (url.includes(developmentBuildIP) || url.includes("localhost") || url.includes(developmentBuildHost) || url.includes(prodBuildIP) || url.includes(prodBuildHost)) && <Link to="/intercom/bolero" id="nav-bar-link-intercom" style={{ listStyle: "none", paddingTop: "10px" }}
                  onClick={() => setSide_panel_visible(false)}
                ><HeaderMenuItem>Intercom</HeaderMenuItem></Link>}
                {["full", "view"]?.includes(loggedInUserPageAccess?.shader?.toLowerCase()) && cookie_has_been_assigned && (url.includes(developmentBuildIP) || url.includes("localhost") || url.includes(developmentBuildHost) || url.includes(prodBuildIP) || url.includes(prodBuildHost)) && <Link to="http://lbx-lira.liberty.edu:90/" target="_blank" id="nav-bar-link-lbx-shader-interface" style={{ listStyle: "none", paddingTop: "10px" }}
                  onClick={() => setSide_panel_visible(false)}
                ><HeaderMenuItem>LBX Shader Interface</HeaderMenuItem></Link>}
                {/* {["full", "view"]?.includes(loggedInUserPageAccess?.magnum?.toLowerCase()) && cookie_has_been_assigned && (url.includes(developmentBuildIP) || url.includes("localhost") || url.includes(developmentBuildHost) || url.includes(prodBuildIP) || url.includes(prodBuildHost)) && <Link to="/magnum" id="nav-bar-link-mobile-magnum" style={{ listStyle: "none", paddingTop: "10px" }}
                  onClick={() => setSide_panel_visible(false)}
                ><HeaderMenuItem>Mobile Magnum</HeaderMenuItem></Link>} */}
                {["full", "view"]?.includes(loggedInUserPageAccess?.monitoring?.toLowerCase()) && cookie_has_been_assigned && (url.includes(developmentBuildIP) || url.includes("localhost") || url.includes(developmentBuildHost) || url.includes(prodBuildIP) || url.includes(prodBuildHost)) && <Link to="/monitoring" id="nav-bar-link-monitoring" style={{ listStyle: "none", paddingTop: "10px" }}
                  onClick={() => setSide_panel_visible(false)}
                ><HeaderMenuItem>Monitoring</HeaderMenuItem></Link>}
                {["full", "view"]?.includes(loggedInUserPageAccess?.nmos?.toLowerCase()) && cookie_has_been_assigned && (url.includes(developmentBuildIP) || url.includes("localhost") || url.includes(developmentBuildHost) || url.includes(prodBuildIP) || url.includes(prodBuildHost)) && <Link to="/nmos" id="nav-bar-link-nmos" style={{ listStyle: "none", paddingTop: "10px" }}
                  onClick={() => setSide_panel_visible(false)}
                ><HeaderMenuItem>NMOS Patching</HeaderMenuItem></Link>}
                {["full", "view"]?.includes(loggedInUserPageAccess?.omni?.toLowerCase()) && cookie_has_been_assigned && (url.includes(developmentBuildIP) || url.includes("localhost") || url.includes(developmentBuildHost) || url.includes(prodBuildIP) || url.includes(prodBuildHost)) && <Link to="/omni" id="nav-bar-link-omni" style={{ listStyle: "none", paddingTop: "10px" }}
                  onClick={() => setSide_panel_visible(false)}
                ><HeaderMenuItem>Omni Router</HeaderMenuItem></Link>}
                {["full", "view"]?.includes(loggedInUserPageAccess?.polatis?.toLowerCase()) && cookie_has_been_assigned && (url.includes(developmentBuildIP) || url.includes("localhost") || url.includes(developmentBuildHost) || url.includes(prodBuildIP) || url.includes(prodBuildHost)) && <Link to="/polatis" id="nav-bar-link-polatis" style={{ listStyle: "none", paddingTop: "10px" }}
                  onClick={() => setSide_panel_visible(false)}
                ><HeaderMenuItem>Polatis</HeaderMenuItem></Link>}
                {["full", "view"]?.includes(loggedInUserPageAccess?.radio?.toLowerCase()) && cookie_has_been_assigned && (url.includes(developmentBuildIP) || url.includes("localhost") || url.includes(developmentBuildHost) || url.includes(prodBuildIP) || url.includes(prodBuildHost)) && <Link to="/radio" id="nav-bar-link-radio" style={{ listStyle: "none", paddingTop: "10px" }}
                  onClick={() => setSide_panel_visible(false)}
                ><HeaderMenuItem>Radio</HeaderMenuItem></Link>}
                {["full", "view"]?.includes(loggedInUserPageAccess?.roku?.toLowerCase()) && cookie_has_been_assigned && (url.includes(developmentBuildIP) || url.includes("localhost") || url.includes(developmentBuildHost) || url.includes(prodBuildIP) || url.includes(prodBuildHost)) && <Link to="/roku/1" id="nav-bar-link-roku" style={{ listStyle: "none", paddingTop: "10px" }}
                  onClick={() => setSide_panel_visible(false)}
                ><HeaderMenuItem>Roku</HeaderMenuItem></Link>}
                {["full", "view"]?.includes(loggedInUserPageAccess?.salvos?.toLowerCase()) && cookie_has_been_assigned && (url.includes(developmentBuildIP) || url.includes("localhost") || url.includes(developmentBuildHost) || url.includes(prodBuildIP) || url.includes(prodBuildHost)) && <Link to="/salvos" id="nav-bar-link-salvos" style={{ listStyle: "none", paddingTop: "10px" }}
                  onClick={() => setSide_panel_visible(false)}
                ><HeaderMenuItem>Salvos</HeaderMenuItem></Link>}
                {["full", "view"]?.includes(loggedInUserPageAccess?.schedule?.toLowerCase()) && cookie_has_been_assigned && (url.includes(developmentBuildIP) || url.includes("localhost") || url.includes(developmentBuildHost) || url.includes(prodBuildIP) || url.includes(prodBuildHost)) && <Link to="/schedule" id="nav-bar-link-schedule" style={{ listStyle: "none", paddingTop: "10px" }}
                  onClick={() => {
                    setSide_panel_visible(false)
                    setScheduleSubLink("schedule")
                  }}
                ><HeaderMenuItem>Schedule</HeaderMenuItem></Link>}
            </SideNavItems>
          </SideNav>
          <HeaderGlobalBar>
            {/* <HeaderGlobalAction id="navBarNotificationButton" aria-label="Notifications" tooltipAlignment="center" onClick={(e) => {
              selectedGlobalAction === "notification" ? setSelectedGlobalAction("") : setSelectedGlobalAction("notification")
            }}
              isActive={selectedGlobalAction === "notification" ? true : false}
            >
              <Notification size={20} />
            </HeaderGlobalAction> */}
            <HeaderGlobalAction id="navBarUserAvatarButton" aria-label="Account Info" tooltipAlignment="end" onClick={(e) => {
              if (!active_account && !window.location.href.includes("localhost")) {
                login_popup_func()
              } else {
                console.log("User Avatar Clicked", appSwitcherVisible, selectedGlobalAction)
                // if(appSwitcherVisible && selectedGlobalAction === "user") {
                //   setTimeout(() => {
                //     setAppSwitcherVisible(false)
                //     setSelectedGlobalAction("")
                //   }, 100)
                // }
                selectedGlobalAction === "user" ? setSelectedGlobalAction("") : setSelectedGlobalAction("user")
              }
            }}
              isActive={selectedGlobalAction === "user" ? true : false}
            >
              <UserAvatar size={20} />
            </HeaderGlobalAction>
            {/* <HeaderGlobalAction id="navBarSettingsButton" aria-label="Settings" tooltipAlignment="center" onClick={(e) => {
              // navigate("/settings")
              setSettingsModalInfo({
                open: true
              })
              setSettings_routing_type(null)
              if (!active_account && !window.location.href.includes("localhost")) {
                login_popup_func()
              } else {
                selectedGlobalAction === "setting" ? setSelectedGlobalAction("") : setSelectedGlobalAction("setting")
              }
            }}
              isActive={selectedGlobalAction === "setting" ? false : false}
            >
              <Settings size={20} />
            </HeaderGlobalAction> */}
            {/* <HeaderGlobalAction id="navBarSwitcherButton" aria-label="External Links" tooltipAlignment="end" onClick={(e) => {
              if (!active_account && !window.location.href.includes("localhost")) {
                login_popup_func()
              } else {
                // if(appSwitcherVisible && selectedGlobalAction === "switcher") {
                //   setTimeout(() => {
                //     setAppSwitcherVisible(false)
                //     setSelectedGlobalAction("")
                //   }, 100)
                // } else {
                // }
                setSelectedGlobalAction("switcher")
                selectedGlobalAction === "switcher" ? setSelectedGlobalAction("") : setSelectedGlobalAction("switcher")
              }
            }}
              isActive={selectedGlobalAction === "switcher" ? true : false}
            >
              <SwitcherIcon id="navBarSwitcherIcon" size={20} />
            </HeaderGlobalAction> */}
          </HeaderGlobalBar>
          {<HeaderPanel id="accountInfoPanel" expanded={selectedGlobalAction === "user" && appSwitcherVisible ? true : false}>
            <Switcher id="userAvatarPanel" aria-label="LIRA User" style={{ position: "relative" }}>
              <span style={{
                marginTop: "1rem",
                textAlign: "center",
                width: "90%",
                fontSize: "16px"
              }}>Account Info</span>
              <SwitcherDivider />
              {global_account_access_tier && <div className='flex w-[90%] gap-2 p-2 text-left text-md'><FaUser style={{ width: "20px", height: "20px" }} />{account_info_from_cookie["name"] ? account_info_from_cookie["name"] : active_account?.name}</div>}
              {global_account_access_tier && <div className='flex w-[90%] gap-2 p-2 text-left text-md'><MdEmail style={{ minWidth: "20px", minHeight: "20px" }} />{account_info_from_cookie["username"] ? account_info_from_cookie["username"] : active_account?.username}</div>}
              {/* {global_account_access_tier && <div className='flex w-[90%] gap-2 p-2 text-left text-md'><FaInfoCircle style={{ width: "20px", height: "20px" }} />{`${global_account_access_tier[0]?.toUpperCase()}${global_account_access_tier?.slice(1)}`}</div>} */}
              {/* {global_account_access_tier && <div className='flex w-[90%] gap-2 p-2 text-left text-md'><FaInfoCircle style={{ width: "20px", height: "20px" }} />{`${account_info_from_cookie?.["liraTiers"]}`}</div>} */}
              {global_account_access_tier && cookie_has_been_assigned && <div className='flex w-[90%] gap-2 p-2 text-left text-md hover:cursor-pointer hover:text-red-400'
                onClick={() => {
                  logout_func()
                }}
              ><Logout style={{ width: "20px", height: "20px" }} />Logout</div>}
              <p className='absolute text-sm bottom-2 right-2'>v{productionBuildVersion}</p>
            </Switcher>
          </HeaderPanel>}
          <HeaderPanel expanded={selectedGlobalAction === "switcher" && appSwitcherVisible ? true : false}>
            <Switcher id="appSwitcherPanel" aria-label="LIRA Switcher">
              <span style={{
                marginTop: "1rem",
                textAlign: "left",
                width: "90%",
                fontSize: "14px"
              }}>Favorites</span>
              <SwitcherDivider />
              <SwitcherItem href="https://10.239.0.24/launcher/" target='_blank' aria-label="">Magnum</SwitcherItem>
              <SwitcherItem href="http://10.239.0.14" target='_blank' aria-label="">Scheduler</SwitcherItem>
              <SwitcherItem href="https://10.239.1.61" target='_blank' aria-label="">DC Media Gateway</SwitcherItem>
              <span style={{
                marginTop: "1rem",
                textAlign: "left",
                width: "90%",
                fontSize: "14px"
              }}>Engineering</span>
              <SwitcherDivider />
              <SwitcherItem href="http://10.239.70.254" target='_blank' aria-label="">Arena FS</SwitcherItem>
              {(url.includes(developmentBuildIP) || url.includes("localhost") || url.includes(developmentBuildHost) || url.includes(prodBuildIP) || url.includes(prodBuildHost)) && <SwitcherItem href="http://10.239.70.253" target='_blank' aria-label="">Arena KiPro</SwitcherItem>}
              <SwitcherItem href="https://bitbucket.liberty.edu/projects/LUBO" target='_blank' aria-label="">BitBucket</SwitcherItem>
              <SwitcherItem href="http://10.239.60.63/" target='_blank' aria-label="">Calrec Connect</SwitcherItem>
              <SwitcherItem href="http://10.239.60.60:8080" target='_blank' aria-label="">Calrec H20</SwitcherItem>
              <SwitcherItem href="https://switchboard.comrex.com/switchboard/units" target='_blank' aria-label="">Comrex Switchboard</SwitcherItem>
              <SwitcherItem href="https://wiki.os.liberty.edu/pages/viewpage.action?spaceKey=BE&title=Broadcast+Home" target='_blank' aria-label="">Confluence</SwitcherItem>
              <SwitcherItem href="https://luddm.liberty.edu" target='_blank' aria-label="">Dante Domain Manager</SwitcherItem>
              {(url.includes(developmentBuildIP) || url.includes("localhost") || url.includes(developmentBuildHost) || url.includes(prodBuildIP) || url.includes(prodBuildHost)) && <SwitcherItem href="https://10.239.1.31" target='_blank' aria-label="">DC CAP 01</SwitcherItem>}
              {(url.includes(developmentBuildIP) || url.includes("localhost") || url.includes(developmentBuildHost) || url.includes(prodBuildIP) || url.includes(prodBuildHost)) && <SwitcherItem href="https://10.239.1.32" target='_blank' aria-label="">DC CAP 02</SwitcherItem>}
              {(url.includes(developmentBuildIP) || url.includes("localhost") || url.includes(developmentBuildHost) || url.includes(prodBuildIP) || url.includes(prodBuildHost)) && <SwitcherItem href="https://10.239.1.33" target='_blank' aria-label="">DC CAP 03</SwitcherItem>}
              {(url.includes(developmentBuildIP) || url.includes("localhost") || url.includes(developmentBuildHost) || url.includes(prodBuildIP) || url.includes(prodBuildHost)) && <SwitcherItem href="https://10.239.1.34" target='_blank' aria-label="">DC CAP 04</SwitcherItem>}
              <SwitcherItem href="https://10.7.254.252/streams" target='_blank' aria-label="">ESPN Ad Hoc Encoder</SwitcherItem>
              <SwitcherItem href="https://breakclock.ceas.espn.com/" target='_blank' aria-label="">ESPN Break Clock</SwitcherItem>
              {(url.includes(developmentBuildIP) || url.includes("localhost") || url.includes(developmentBuildHost) || url.includes(prodBuildIP) || url.includes(prodBuildHost)) && <SwitcherItem href="http://10.239.61.42" target='_blank' aria-label="">GH KiPro A/B</SwitcherItem>}
              {(url.includes(developmentBuildIP) || url.includes("localhost") || url.includes(developmentBuildHost) || url.includes(prodBuildIP) || url.includes(prodBuildHost)) && <SwitcherItem href="http://10.7.254.248" target='_blank' aria-label="">Helo Plus</SwitcherItem>}
              {(url.includes(developmentBuildIP) || url.includes("localhost") || url.includes(developmentBuildHost) || url.includes(prodBuildIP) || url.includes(prodBuildHost)) && <SwitcherItem href="http://10.239.170.244" target='_blank' aria-label="">Hockey FS</SwitcherItem>}
              {(url.includes(developmentBuildIP) || url.includes("localhost") || url.includes(developmentBuildHost) || url.includes(prodBuildIP) || url.includes(prodBuildHost)) && <SwitcherItem href="http://10.239.170.247" target='_blank' aria-label="">Hockey KiPro</SwitcherItem>}
              <SwitcherItem href="https://apex.liberty.edu/banprd/f?p=122:150:15840983068341:::::" target='_blank' aria-label="">LBX Crewing</SwitcherItem>
              <SwitcherItem href="https://lbx-sync-gen-01.university.liberty.edu" target='_blank' aria-label="">LBX Sync Gen 01</SwitcherItem>
              <SwitcherItem href="http://lbx-sync-gen-02.liberty.edu/" target='_blank' aria-label="">LBX Sync Gen 02</SwitcherItem>
              <SwitcherItem href="https://lbx-sync-gen-03.liberty.edu" target='_blank' aria-label="">LBX Sync Gen 03</SwitcherItem>
              <SwitcherItem href="https://lbx-qc.liberty.edu/probe/services" target='_blank' aria-label="">LBX QC</SwitcherItem>
              {/* {(url.includes(developmentBuildIP) || url.includes("localhost") || url.includes(developmentBuildHost) || url.includes(prodBuildIP) || url.includes(prodBuildHost)) && <SwitcherItem href="http://lubo-polatis-router.liberty.edu" target="_blank" aria-label="">Polatis Duplex Router</SwitcherItem>} */}
              <SwitcherItem href="https://10.239.0.32/cgi-bin/login" target='_blank' aria-label="">Polatis</SwitcherItem>
              <SwitcherItem href=" https://lbx-trackhound.liberty.edu" target='_blank' aria-label="">PTP TrackHound</SwitcherItem>
              <SwitcherItem href="http://10.7.50.251/#view" target='_blank' aria-label="">PTZ North Tower</SwitcherItem>
              <SwitcherItem href="http://10.7.50.252/#view" target='_blank' aria-label="">PTZ South Tower</SwitcherItem>
              <SwitcherItem href="http://10.7.50.254/#view" target='_blank' aria-label="">PTZ West Tower</SwitcherItem>
              {(url.includes(developmentBuildIP) || url.includes("localhost") || url.includes(developmentBuildHost) || url.includes(prodBuildIP) || url.includes(prodBuildHost)) && <SwitcherItem href="http://10.245.84.248/nodes_view" target='_blank' aria-label="">Riedel Bolero</SwitcherItem>}
              {/* <SwitcherItem href="http://lbx-lira-dev.liberty.edu:200" target='_blank' aria-label="">Riedel Control</SwitcherItem> */}
              <SwitcherItem href="https://libertyflames.com/watch" target='_blank' aria-label="">Sidearm</SwitcherItem>
              <SwitcherItem href="http://tally01.liberty.edu:4000/login" target='_blank' aria-label="">Tally</SwitcherItem>
              <p style={{
                height: "2rem"
              }}></p>
            </Switcher>
          </HeaderPanel>
        </Header>
      )}
    />
  );
}


// export default NavBar;
