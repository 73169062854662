import { Column, Grid, Theme, Button } from "@carbon/react"
import "../global.css"
import { useMsal } from "@azure/msal-react";

export default function WelcomePage({
    handleLoginPopup,
}) {
    return (
        <Theme theme="g90" style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "-3rem",
            height: "calc(100vh + 3rem)",
        }}>
            <div id="lira-home-container" className="flex flex-col mt-[3rem] gap-4 justify-center items-center">
                <h1 className="" style={{ fontFamily: "IBM Plex Sans", fontWeight: "500" }}>Welcome to LBX</h1>
                <Button hasIconOnly style={{ minWidth: 0, width: "200px", fontSize: "16px" }}
                    onClick={() => {
                        handleLoginPopup()
                    }}
                >Please click here to login</Button>
            </div>
        </Theme>
    )
}