import { Button } from "@carbon/react"
import { useEffect } from "react"

export default function UnableToAccessPage({
    handleLoginPopup,
    whatPagesCanThisUserAccess,
    serverPermissions,
}) {

    useEffect(() => {
        whatPagesCanThisUserAccess(serverPermissions)
    }, [serverPermissions])

    return (
        <div className="w-full h-[calc(100vh-3rem)] flex flex-col justify-center items-center gap-10">
            <h1 className="className='mt-[10rem] text-white w-full text-center text'">Your account has not been granted access to this page</h1>
            <div className="flex justify-center w-full gap-1 text-white">
                <p className="text-inherit">Click</p>
                <p className="font-bold cursor-pointer hover:underline"
                    onClick={() => {
                        handleLoginPopup()
                    }}
                >here</p>
                <p>if you need to log back in</p>
            </div>
            {/* <Button hasIconOnly style={{ minWidth: 0, width: "200px", fontSize: "16px" }}
                    onClick={() => {
                        handleLoginPopup()
                    }}
            >Log in if session expired</Button> */}
        </div>
    )
}