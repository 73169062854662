import { useEffect, useState } from "react";
import { HexColorPicker, HexColorInput } from "react-colorful";
import { Modal, Button, Dropdown, TextInput, Tile, Select, SelectItem, Checkbox, MultiSelect, Tooltip } from '@carbon/react'
import { FaTrash } from "react-icons/fa";
import "../global.css"


export default function ScheduleConfigureFavorites({
    localIP,
    sendServerNewLog,
    account_info_from_cookie,
    active_account,
    newEventPossibleClients,
    newEventPossibleCoverages,
    newEventPossibleEICs,
    newEventPossibleEncoders,
    newEventPossibleEvents,
    newEventPossibleLocations,
    newEventPossibleORGs,
    newEventPossiblePCRs,
    newEventPossiblePlatforms,
    newEventPossibleProducers,
    newEventPossibleTMs,
    newEventPossibleTypes,
    isModalConfigureFavoritesInfo,
    setIsModalConfigureFavoritesInfo,
    scheduleConfiguredFavorites,
    setScheduleConfiguredFavorites,
    backupScheduleConfiguredFavorites,
    allPossibleColumnsInOrder,
    userPreferences,
    setUserPreferences,
    fetchUserPreferences,
    postUserPreferences,
}) {

    const maxFavoritesPerUser = 3

    useEffect(() => {
        try {
            document.getElementById("schedule-configure-favorites").querySelector(".cds--modal-container").style.minHeight = "85%"
        } catch(err) {
            console.log(err)
        }
    }, [])

    return (
        <Modal id="schedule-configure-favorites" open={isModalConfigureFavoritesInfo?.open} preventCloseOnClickOutside
        // <Modal id="schedule-configure-favorites" open={true}
            primaryButtonText="Save" secondaryButtonText="Cancel"
            onRequestClose={() => {
                // console.log(localIP, account_info_from_cookie?.username?.split("@")[0])
                fetchUserPreferences(localIP, account_info_from_cookie?.username?.split("@")[0])
                .then((data) => {
                    setUserPreferences(data)
                    console.log("user preferences", data)
                    if(data?.hasOwnProperty("schedule-configured-favorites")) {
                        setScheduleConfiguredFavorites(data["schedule-configured-favorites"])
                    } else {
                        setScheduleConfiguredFavorites([[]])
                    }
                })
                setIsModalConfigureFavoritesInfo(false)
            }}
            onRequestSubmit={() => {
                console.log("saving favorites", scheduleConfiguredFavorites)
                setUserPreferences({ ...userPreferences, "schedule-configured-favorites": scheduleConfiguredFavorites })
                postUserPreferences(localIP, account_info_from_cookie?.username?.split("@")[0], {
                    ...userPreferences, "schedule-configured-favorites": scheduleConfiguredFavorites
                })
                .then(res => {
                    if(res?.hasOwnProperty("error")) {
                        alert(res?.error)
                    }
                })
                setIsModalConfigureFavoritesInfo(false)
            }}
        >
            <div className="flex flex-col gap-4">
                {scheduleConfiguredFavorites?.map((favorite, favoriteIdx) => {
                    // console.log("favorite", favorite)
                    return (
                        <div className="flex flex-col">
                            <p>Favorite {favoriteIdx + 1} of {maxFavoritesPerUser}</p>
                            <Tile
                                style={{
                                    borderRadius: "8px",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    gap: "1rem",
                                    width: "100%",
                                    minHeight: "100px",
                                    backgroundColor: "rgb(83,83,83)",
                                    marginTop: "1rem",
                                }}
                                className="schedule-configure-favorites-tile"
                            >
                                <div className="flex justify-between">
                                    {/* <h2 className="text-2xl font-bold">{favorite?.name}</h2> */}
                                    <TextInput placeholder="Enter favorite name" value={favorite?.name}
                                        style={{
                                            fontSize: "20px"
                                        }}
                                        onChange={(e) => {
                                            const newPrev = [...scheduleConfiguredFavorites]
                                            newPrev[favoriteIdx].name = e.target.value
                                            setScheduleConfiguredFavorites(newPrev)
                                        }}
                                    />
                                    <Tooltip id="lira-schedule-overflow-menu-tooltip" align="top-right" label="Delete Favorite">
                                        <div className="cursor-pointer text-red-600 hover:text-[red]"
                                            style={{ fontSize: "20px" }}
                                            onClick={() => {
                                                const newPrev = [...scheduleConfiguredFavorites]
                                                newPrev.splice(favoriteIdx, 1)
                                                setScheduleConfiguredFavorites(newPrev)
                                            }}
                                            >
                                            <svg focusable="false" preserveAspectRatio="xMidYMid meet" fill="currentColor" aria-hidden="true" width="20" height="20" viewBox="0 0 32 32" className="fill-red-600 hover:fill-[red] scale-125" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M17.4141 16L24 9.4141 22.5859 8 16 14.5859 9.4143 8 8 9.4141 14.5859 16 8 22.5859 9.4143 24 16 17.4141 22.5859 24 24 22.5859 17.4141 16z"></path>
                                            </svg>
                                        </div>
                                    </Tooltip>
                                </div>
                                {/* <p>Favorite Name</p>
                                <TextInput placeholder="Enter favorite name" value={favorite?.name}
                                    onChange={(e) => {
                                        const newPrev = [...scheduleConfiguredFavorites]
                                        newPrev[favoriteIdx].name = e.target.value
                                        setScheduleConfiguredFavorites(newPrev)
                                    }}
                                /> */}
                                <div className="">
                                    <Checkbox labelText="Set as default view" id={`checkbox-favorite-enable-on-load-${favoriteIdx}`} checked={favorite?.enabledOnLoad} 
                                        onClick={(e) => {
                                            // console.log(e.target.checked, favorite)
                                            const newPrev = [...scheduleConfiguredFavorites]
                                            newPrev.forEach(fav => {
                                                fav.enabledOnLoad = false
                                            })
                                            newPrev[favoriteIdx].enabledOnLoad = e.target.checked
                                            setScheduleConfiguredFavorites(newPrev)
                                        }}
                                        />
                                </div>
                                <h3 className="text-xl font-bold">Rules</h3>
                                {favorite?.rules?.map((rule, index) => {
                                    return <ScheduleConfigureFavoritesSingleRule key={index} ruleIdx={index} favoriteIdx={favoriteIdx} rule={rule}
                                        setScheduleConfiguredFavorites={setScheduleConfiguredFavorites}
                                        newEventPossibleClients={newEventPossibleClients}
                                        newEventPossibleCoverages={newEventPossibleCoverages}
                                        newEventPossibleEICs={newEventPossibleEICs}
                                        newEventPossibleEncoders={newEventPossibleEncoders}
                                        newEventPossibleEvents={newEventPossibleEvents}
                                        newEventPossibleLocations={newEventPossibleLocations}
                                        newEventPossibleORGs={newEventPossibleORGs}
                                        newEventPossiblePCRs={newEventPossiblePCRs}
                                        newEventPossiblePlatforms={newEventPossiblePlatforms}
                                        newEventPossibleProducers={newEventPossibleProducers}
                                        newEventPossibleTMs={newEventPossibleTMs}
                                        newEventPossibleTypes={newEventPossibleTypes}
                                        allPossibleColumnsInOrder={allPossibleColumnsInOrder}
                                        scheduleConfiguredFavorites={scheduleConfiguredFavorites}
                                    />
                                })}
                                <div className="relative flex items-center justify-between w-full mt-4 hide-tooltip">
                                    <Button kind="secondary" hasIconOnly
                                        style={{ width: "100px" }}
                                        onClick={() => {
                                            const newPrev = [...scheduleConfiguredFavorites]
                                            // console.log("new prev", newPrev)
                                            if(newPrev[favoriteIdx]?.rules?.length > 0) {
                                                newPrev[favoriteIdx] = {
                                                    enabledOnLoad: newPrev[favoriteIdx]?.enabledOnLoad || false,
                                                    rules: [...newPrev[favoriteIdx]?.rules, {
                                                        header: "Event", rule: "Contains", value: [] 
                                                    }],
                                                    name: newPrev[favoriteIdx]?.name
                                                }
                                            } else {
                                                newPrev[favoriteIdx] = {
                                                    enabledOnLoad: false,
                                                    rules: [{
                                                        header: "Event", rule: "Contains", value: []
                                                    }],
                                                    name: newPrev[favoriteIdx]?.name
                                                }
                                            }
                                            // console.log("new full list", newPrev)
                                            // console.log("new single favorite", newPrev[favoriteIdx])
                                            setScheduleConfiguredFavorites(newPrev)
                                        }}
                                    >Add Rule</Button>
                                </div>
                            </Tile>
                        </div>
                    )
                })}
                <Button kind="secondary" disabled={scheduleConfiguredFavorites?.length >= maxFavoritesPerUser}
                    onClick={() => {
                        const newPrev = [...scheduleConfiguredFavorites]
                        newPrev.push({
                            enabledOnLoad: false,
                            name: `Favorite ${scheduleConfiguredFavorites?.length + 1}`,
                            rules: [
                                {
                                    header: "Event", rule: "Contains", value: []
                                }
                            ]
                        })
                        // console.log("new prev", newPrev)
                        setScheduleConfiguredFavorites(newPrev)
                    }}
                >{scheduleConfiguredFavorites?.length < maxFavoritesPerUser ? `Add New Favorite` : `Maxed at ${maxFavoritesPerUser} favorites`}</Button>
            </div>
        </Modal>
    )
}

function ScheduleConfigureFavoritesSingleRule({
    rule,
    favoriteIdx,
    ruleIdx,
    setScheduleConfiguredFavorites,
    newEventPossibleClients,
    newEventPossibleCoverages,
    newEventPossibleEICs,
    newEventPossibleEncoders,
    newEventPossibleEvents,
    newEventPossibleLocations,
    newEventPossibleORGs,
    newEventPossiblePCRs,
    newEventPossiblePlatforms,
    newEventPossibleProducers,
    newEventPossibleTMs,
    newEventPossibleTypes,
    allPossibleColumnsInOrder,
    scheduleConfiguredFavorites,
}) {
    // console.log("rule", rule)
    // console.log("value", rule?.value)
    // console.log("new event possible events", newEventPossibleEvents)
    return (
        <div style={{
            
        }}>
            <div className="grid items-center" style={{ gridTemplateColumns: "1fr 1fr 1fr 40px" }}>
                <Select id={`favorite-${favoriteIdx}-rule-header-${ruleIdx}`} className="cell-in-editing" value={rule?.header}
                    style={{ minWidth: `${rule?.header?.toLowerCase()?.length + 4}ch` }}
                    onChange={(e) => {
                        console.log("e.target.value", e.target.value)
                        const newHeader = e.target.value
                        const newPrev = [...scheduleConfiguredFavorites]
                        newPrev[favoriteIdx].rules[ruleIdx] = { ...rule, rule: newHeader?.toLowerCase() === "date" ? "Before Now" : "Contains", header: newHeader, value: [] }
                        setScheduleConfiguredFavorites(newPrev)
                    }}
                >
                    {allPossibleColumnsInOrder?.map((header, index) => {
                        if(["Start Time"]?.includes(header)) return null
                        return <SelectItem key={index} text={header} value={header} />
                    })}
                </Select>
                {["Day", "Event", "Name", "Location", "PCR", "Platform", "Encoder", "Ticket", "Producer", "EIC", "Field Engineer", "Tech Manager", "Coverage", "ORG", "Client", "Type", "Notes"]?.includes(rule?.header) && <Select id={`favorite-${favoriteIdx}-rule-${ruleIdx}`} className="cell-in-editing" value={rule?.rule}
                    style={{ minWidth: `${rule?.header?.toLowerCase()?.length + 4}ch` }}
                    onChange={(e) => {
                        console.log("e.target.value", e.target.value)
                        const newLogicCheck = e.target.value
                        const newPrev = [...scheduleConfiguredFavorites]
                        newPrev[favoriteIdx].rules[ruleIdx] = { ...rule, rule: newLogicCheck }
                        setScheduleConfiguredFavorites(newPrev)
                    }}
                >
                    {["Contains", "Does Not Contain", "Blank", "Not Blank"]?.map((value, index) => {
                        return <SelectItem key={index} text={value} value={value} />
                    })}
                </Select>}
                {["Date"]?.includes(rule?.header) && <Select id={`favorite-${favoriteIdx}-rule-${ruleIdx}`} className="cell-in-editing" value={rule?.rule}
                    style={{ minWidth: `${rule?.header?.toLowerCase()?.length + 4}ch` }}
                    onChange={(e) => {
                        console.log("e.target.value", e.target.value)
                        const newLogicCheck = e.target.value
                        const newPrev = [...scheduleConfiguredFavorites]
                        newPrev[favoriteIdx].rules[ruleIdx] = { ...rule, rule: newLogicCheck }
                        setScheduleConfiguredFavorites(newPrev)
                    }}
                >
                    {["Before Now", "Hide Past Events", "Next 7 Days", "Next 30 Days", "This Month", "Next Month", "Last 7 Days", "Last 30 Days"]?.map((value, index) => {
                        return <SelectItem key={index} text={value} value={value} />
                    })}
                </Select>}
                {["Blank", "Before Now", "Hide Past Events", "Next 7 Days", "Next 30 Days", "This Month", "Next Month", "Last 7 Days", "Last 30 Days"]?.includes(rule?.rule) ? <div className="cell-in-editing bg-[rgb(40,40,40)] h-[47px] left-[2px] relative top-[2px] opacity-50"
                    style={{ outline: "1px solid rgb(168, 168, 168)", border: "0" }}
                ></div> : <div className="inline-block">
                    {["Day", "Name", "Ticket"]?.includes(rule?.header) && <TextInput className="cell-in-editing" id={`favorite-${favoriteIdx}-rule-value-${ruleIdx}`} value={rule?.value?.join(" ")} onChange={(e) => {
                        console.log("e.target.value", e.target.value)
                        const newPrev = [...scheduleConfiguredFavorites]
                        newPrev[favoriteIdx].rules[ruleIdx] = { ...rule, value: [e.target.value] }
                        setScheduleConfiguredFavorites(newPrev)
                    }}/>}
                    {rule?.header === "Event" && <MultiSelect className="cell-in-editing" size="lg" items={newEventPossibleEvents?.filter(each => each?.isVisible)?.map(each => each?.label)} itemToString={item => item} placeholder={"Select Here"} label="Select Here"
                        selectedItems={rule?.value}
                        onChange={(selected_items_array) => {
                            console.log('Selected items:', selected_items_array?.selectedItems);
                            const selectedItems = selected_items_array?.selectedItems || selected_items_array?.selectedItem
                            setScheduleConfiguredFavorites(prev => {
                                const newPrev = [...prev]
                                newPrev[favoriteIdx].rules[ruleIdx] = { ...rule, value: selectedItems }
                                // console.log("newPrev", newPrev)
                                return newPrev
                            })
                        }} style={{ minWidth: "30ch" }}
                    />}
                    {rule?.header === "Location" && <MultiSelect className="cell-in-editing" size="lg" items={newEventPossibleLocations?.filter(each => each?.isVisible)?.map(each => each?.label)} itemToString={item => item} placeholder={"Select Here"} label="Select Here"
                        selectedItems={rule?.value}
                        onChange={(selected_items_array) => {
                            console.log('Selected items:', selected_items_array?.selectedItems);
                            const selectedItems = selected_items_array?.selectedItems || selected_items_array?.selectedItem
                            const newPrev = [...scheduleConfiguredFavorites]
                            newPrev[favoriteIdx].rules[ruleIdx] = { ...rule, value: selectedItems }
                            setScheduleConfiguredFavorites(newPrev)
                        }} style={{ minWidth: "30ch" }}
                    />}
                    {rule?.header === "PCR" && <MultiSelect className="cell-in-editing" size="lg" items={newEventPossiblePCRs?.filter(each => each?.isVisible)?.map(each => each?.label)} itemToString={item => item} placeholder={"Select Here"} label="Select Here"
                        selectedItems={rule?.value}
                        onChange={(selected_items_array) => {
                            console.log('Selected items:', selected_items_array?.selectedItems);
                            const selectedItems = selected_items_array?.selectedItems || selected_items_array?.selectedItem
                            const newPrev = [...scheduleConfiguredFavorites]
                            newPrev[favoriteIdx].rules[ruleIdx] = { ...rule, value: selectedItems }
                            setScheduleConfiguredFavorites(newPrev)
                        }} style={{ minWidth: "30ch" }}
                    />}
                    {rule?.header === "Platform" && <MultiSelect className="cell-in-editing" size="lg" items={newEventPossiblePlatforms?.filter(each => each?.isVisible)?.map(each => each?.label)} itemToString={item => item} placeholder={"Select Here"} label="Select Here"
                        selectedItems={rule?.value}
                        onChange={(selected_items_array) => {
                            console.log('Selected items:', selected_items_array?.selectedItems);
                            const selectedItems = selected_items_array?.selectedItems || selected_items_array?.selectedItem
                            const newPrev = [...scheduleConfiguredFavorites]
                            newPrev[favoriteIdx].rules[ruleIdx] = { ...rule, value: selectedItems }
                            setScheduleConfiguredFavorites(newPrev)
                        }} style={{ minWidth: "30ch" }}
                    />}
                    {rule?.header === "Encoder" && <MultiSelect className="cell-in-editing" size="lg" items={newEventPossibleEncoders?.filter(each => each?.isVisible)?.map(each => each?.label)} itemToString={item => item} placeholder={"Select Here"} label="Select Here"
                        selectedItems={rule?.value}
                        onChange={(selected_items_array) => {
                            console.log('Selected items:', selected_items_array?.selectedItems);
                            const selectedItems = selected_items_array?.selectedItems || selected_items_array?.selectedItem
                            const newPrev = [...scheduleConfiguredFavorites]
                            newPrev[favoriteIdx].rules[ruleIdx] = { ...rule, value: selectedItems }
                            setScheduleConfiguredFavorites(newPrev)
                        }} style={{ minWidth: "30ch" }}
                    />}
                    {rule?.header === "Producer" && <MultiSelect className="cell-in-editing" size="lg" items={newEventPossibleProducers?.filter(each => each?.isVisible)?.map(each => each?.label)} itemToString={item => item} placeholder={"Select Here"} label="Select Here"
                        selectedItems={rule?.value}
                        onChange={(selected_items_array) => {
                            console.log('Selected items:', selected_items_array?.selectedItems);
                            const selectedItems = selected_items_array?.selectedItems || selected_items_array?.selectedItem
                            const newPrev = [...scheduleConfiguredFavorites]
                            newPrev[favoriteIdx].rules[ruleIdx] = { ...rule, value: selectedItems }
                            setScheduleConfiguredFavorites(newPrev)
                        }} style={{ minWidth: "30ch" }}
                    />}
                    {rule?.header === "EIC" && <MultiSelect className="cell-in-editing" size="lg" items={newEventPossibleEICs?.filter(each => each?.isVisible)?.map(each => each?.label)} itemToString={item => item} placeholder={"Select Here"} label="Select Here"
                        selectedItems={rule?.value}
                        onChange={(selected_items_array) => {
                            console.log('Selected items:', selected_items_array?.selectedItems);
                            const selectedItems = selected_items_array?.selectedItems || selected_items_array?.selectedItem
                            const newPrev = [...scheduleConfiguredFavorites]
                            newPrev[favoriteIdx].rules[ruleIdx] = { ...rule, value: selectedItems }
                            setScheduleConfiguredFavorites(newPrev)
                        }} style={{ minWidth: "30ch" }}
                    />}
                    {rule?.header === "Field Engineer" && <MultiSelect className="cell-in-editing" size="lg" items={newEventPossibleEICs?.filter(each => each?.isVisible)?.map(each => each?.label)} itemToString={item => item} placeholder={"Select Here"} label="Select Here"
                        selectedItems={rule?.value}
                        onChange={(selected_items_array) => {
                            console.log('Selected items:', selected_items_array?.selectedItems);
                            const selectedItems = selected_items_array?.selectedItems || selected_items_array?.selectedItem
                            const newPrev = [...scheduleConfiguredFavorites]
                            newPrev[favoriteIdx].rules[ruleIdx] = { ...rule, value: selectedItems }
                            setScheduleConfiguredFavorites(newPrev)
                        }} style={{ minWidth: "30ch" }}
                    />}
                    {rule?.header === "Tech Manager" && <MultiSelect className="cell-in-editing" size="lg" items={newEventPossibleTMs?.filter(each => each?.isVisible)?.map(each => each?.label)} itemToString={item => item} placeholder={"Select Here"} label="Select Here"
                        selectedItems={rule?.value}
                        onChange={(selected_items_array) => {
                            console.log('Selected items:', selected_items_array?.selectedItems);
                            const selectedItems = selected_items_array?.selectedItems || selected_items_array?.selectedItem
                            const newPrev = [...scheduleConfiguredFavorites]
                            newPrev[favoriteIdx].rules[ruleIdx] = { ...rule, value: selectedItems }
                            setScheduleConfiguredFavorites(newPrev)
                        }} style={{ minWidth: "30ch" }}
                    />}
                    {rule?.header === "Coverage" && <MultiSelect className="cell-in-editing" size="lg" items={newEventPossibleCoverages?.filter(each => each?.isVisible)?.map(each => each?.label)} itemToString={item => item} placeholder={"Select Here"} label="Select Here"
                        selectedItems={rule?.value}
                        onChange={(selected_items_array) => {
                            console.log('Selected items:', selected_items_array?.selectedItems);
                            const selectedItems = selected_items_array?.selectedItems || selected_items_array?.selectedItem
                            const newPrev = [...scheduleConfiguredFavorites]
                            newPrev[favoriteIdx].rules[ruleIdx] = { ...rule, value: selectedItems }
                            setScheduleConfiguredFavorites(newPrev)
                        }} style={{ minWidth: "30ch" }}
                    />}
                    {rule?.header === "ORG" && <MultiSelect className="cell-in-editing" size="lg" items={newEventPossibleORGs?.filter(each => each?.isVisible)?.map(each => each?.label)} itemToString={item => item} placeholder={"Select Here"} label="Select Here"
                        selectedItems={rule?.value}
                        onChange={(selected_items_array) => {
                            console.log('Selected items:', selected_items_array?.selectedItems);
                            const selectedItems = selected_items_array?.selectedItems || selected_items_array?.selectedItem
                            const newPrev = [...scheduleConfiguredFavorites]
                            newPrev[favoriteIdx].rules[ruleIdx] = { ...rule, value: selectedItems }
                            setScheduleConfiguredFavorites(newPrev)
                        }} style={{ minWidth: "30ch" }}
                    />}
                    {rule?.header === "Client" && <MultiSelect className="cell-in-editing" size="lg" items={newEventPossibleClients?.filter(each => each?.isVisible)?.map(each => each?.label)} itemToString={item => item} placeholder={"Select Here"} label="Select Here"
                        selectedItems={rule?.value}
                        onChange={(selected_items_array) => {
                            console.log('Selected items:', selected_items_array?.selectedItems);
                            const selectedItems = selected_items_array?.selectedItems || selected_items_array?.selectedItem
                            const newPrev = [...scheduleConfiguredFavorites]
                            newPrev[favoriteIdx].rules[ruleIdx] = { ...rule, value: selectedItems }
                            setScheduleConfiguredFavorites(newPrev)
                        }} style={{ minWidth: "30ch" }}
                    />}
                    {rule?.header === "Type" && <MultiSelect className="cell-in-editing" size="lg" items={newEventPossibleTypes?.filter(each => each?.isVisible)?.map(each => each?.label)} itemToString={item => item} placeholder={"Select Here"} label="Select Here"
                        selectedItems={rule?.value}
                        onChange={(selected_items_array) => {
                            console.log('Selected items:', selected_items_array?.selectedItems);
                            const selectedItems = selected_items_array?.selectedItems || selected_items_array?.selectedItem
                            const newPrev = [...scheduleConfiguredFavorites]
                            newPrev[favoriteIdx].rules[ruleIdx] = { ...rule, value: selectedItems }
                            setScheduleConfiguredFavorites(newPrev)
                        }} style={{ minWidth: "30ch" }}
                    />}
                </div>}
                <Tooltip id="lira-schedule-overflow-menu-tooltip" align="top-right" label="Delete Rule">
                    <div
                        className="text-red-600 hover:text-[red] flex justify-center items-center"
                        style={{ width: "40px", height: "30px", fontSize: "20px", cursor: "pointer" }}
                        onClick={() => {
                            const newPrev = [...scheduleConfiguredFavorites]
                            newPrev[favoriteIdx].rules = newPrev[favoriteIdx].rules.filter((_, index) => index !== ruleIdx)
                            setScheduleConfiguredFavorites(newPrev)
                        }}
                        >
                        <svg focusable="false" preserveAspectRatio="xMidYMid meet" fill="currentColor" aria-hidden="true" width="20" height="20" viewBox="0 0 32 32" className="fill-red-600 hover:fill-[red] scale-125" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.4141 16L24 9.4141 22.5859 8 16 14.5859 9.4143 8 8 9.4141 14.5859 16 8 22.5859 9.4143 24 16 17.4141 22.5859 24 24 22.5859 17.4141 16z"></path>
                        </svg>
                    </div>
                </Tooltip>
                {/* <FaTrash
                    className="text-gray-400 hover:text-[red]"
                    style={{ width: "40px", height: "30px", cursor: "pointer" }}
                    onClick={() => {
                        setScheduleConfiguredFavorites(prev => {
                            const newPrev = [...prev]
                            newPrev[favoriteIdx].rules = newPrev[favoriteIdx].rules.filter((_, index) => index !== ruleIdx)
                            return newPrev
                        })
                    }}
                /> */}
            </div>
        </div>
    )
}