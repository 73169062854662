import { Column, Grid, Theme, Button } from "@carbon/react"
import { useEffect, useState } from "react"
import { ScheduleTable } from "./Schedule"

export default function FullScreenSchedule({
    localIP,
    account_info_from_cookie,
    active_account,
    serverPermissions,
    whatPagesCanThisUserAccess,
    loggedInUserPageAccess,
    liraTiersFromCookie,
    global_account_access_tier,
    authorizationCheck,
    redirectIfUserIsNotAllowedHere,
    quickCheckForCookie,
}) {
    const [dummyState, setDummyState] = useState(null)
    const [scheduleEntries, setScheduleEntries] = useState([])
    const [escapeFullScreenWillAlsoClearSelectedEntry, setEscapeFullScreenWillAlsoClearSelectedEntry] = useState(false);
    const [scrollToFirstEntry, setScrollToFirstEntry] = useState(true);

    const [isRightClickMenuOpen, setIsRightClickMenuOpen] = useState({
        isOpen: false,
        whichCell: null
    });

    async function fetchLBXSchedule() {
        const res = await fetch(`https://${localIP}/lira/schedule/view-full`)
        if(!res.ok) {
            alert("Cannot fetch /schedule/view-full - Status Code: " + res.status)
            return
        }

        const data = await res.json()
        console.log("Data", data);
        // setScheduleEntries([...data]?.slice(data?.length -3))
        setScheduleEntries([...data])
    }

    useEffect(() => {
        document.title = `LBX${localIP?.toLowerCase()?.includes("dev") ? " DEV" : ""} Schedule`
        fetchLBXSchedule()
        
        return () => {
            // setScheduleSearch([""]);
            setScheduleEntries([]);
            // setFilteredScheduleEntries([]);
            // setLastInstanceOfModalEvent({});
            // setColorRules({});
            setScrollToFirstEntry(true);
        }
    }, [])

    useEffect(() => {
        try {
            document.getElementById("lira-schedule-main-container").style.height = "calc(100vh - 3rem)"
            document.getElementById("lira-schedule-main-container").style.maxWidth = "calc(100vw)"
            document.querySelector(".cds--data-table-content").style.width = "100vw"
            document.querySelector(".cds--data-table-content").style.maxWidth = "100vw"
        } catch (error) {
            console.log(error)
        }
    }, [scheduleEntries])

    return (
        <Theme theme="g90" style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "0rem",
            height: "calc(100vh)",
        }}>
           <div className="lira-home-container flex mt-[3rem]">
                <ScheduleTable
                    scheduleEntries={scheduleEntries}
                    setScheduleEntries={setScheduleEntries}
                    todaysEventsSelectedEvent={dummyState}
                    setCurrentHoveredPane={setDummyState}
                    isMainScheduleFullScreen={true}
                    setIsMainScheduleFullScreen={setDummyState}
                    localIP={localIP}
                    account_info_from_cookie={account_info_from_cookie}
                    active_account={active_account}
                    serverPermissions={serverPermissions}
                    whatPagesCanThisUserAccess={whatPagesCanThisUserAccess}
                    loggedInUserPageAccess={loggedInUserPageAccess}
                    liraTiersFromCookie={liraTiersFromCookie}
                    disableFullScreenButton={true}
                    escapeFullScreenWillAlsoClearSelectedEntry={escapeFullScreenWillAlsoClearSelectedEntry}
                    setEscapeFullScreenWillAlsoClearSelectedEntry={setEscapeFullScreenWillAlsoClearSelectedEntry}
                    scrollToFirstEntry={scrollToFirstEntry}
                    setScrollToFirstEntry={setScrollToFirstEntry}
                    isRightClickMenuOpen={isRightClickMenuOpen}
                    setIsRightClickMenuOpen={setIsRightClickMenuOpen}
                />
        </div>
        </Theme>
    )
}